export const getDeviceIdFromCookies = (): string | null => {
  const { userAgent } = navigator
  const { cookie } = document
  const cookies: Record<string, string> = cookie
    ? Object.fromEntries(
        cookie.split('; ').map((cookieItem) => {
          const [name, ...rest] = cookieItem.split('=')

          return [name, rest.join('=')]
        })
      )
    : {}

  const deviceIdKey = Object.keys(cookies).find((key) => key.startsWith('amp_'))
  const deviceId = cookies?.[deviceIdKey || '']?.split('.')?.[0] || userAgent

  return deviceId
}
