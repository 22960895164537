import { useGrowthBook } from '@growthbook/growthbook-react'
import { formatISO } from 'date-fns'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { getDeviceIdFromCookies } from '@promova/utils/getDeviceIdFromCookies'

import { useKeeperValue } from '@api/profile'

import { useAppSelector } from '@reduxStore/hooks'

import { COMPLETED_FIRST_LESSON } from '@constants'

import { SubscriptionStatus } from '@_types/subscription'

import { useQueryCountry } from '@utils/useQueryCountry'
import { EXPERIMENTS_INVOLVED } from '@utils/useUpdateGrowthBookKeeper'

import { useAuthContext } from '@modules/Auth/AuthProvider'

const MINUTES_5 = 1000 * 60 * 5

const useAddGrowthBookAttributes = () => {
  const growthBook = useGrowthBook()
  const { country } = useQueryCountry()
  const { globalUser, user } = useAuthContext()
  const { query, isReady } = useRouter()

  const userId = globalUser?.uid

  const hasCoursesPremium = useAppSelector(
    (state) => state.profile.hasCoursesPremium
  )
  const isSubscriptionFetched = useAppSelector(
    (state) => state.profile.isSubscriptionFetched
  )

  const userProfile = useAppSelector((state) => state.profile.userProfile)
  const isProfileFetched = userProfile?.user_id
  const isFirstLessonCompleted =
    userProfile?.keeper?.[COMPLETED_FIRST_LESSON]?.value || false

  const { data: experimentsData, isLoading: isExperimentsLoading } =
    useKeeperValue({
      params: { path: { key: EXPERIMENTS_INVOLVED } },
      userId: user?.uid || '',
      reactQuery: {
        enabled: !!user,
        staleTime: MINUTES_5,
        refetchOnWindowFocus: false,
      },
    })

  const utmSource = query?.utm_source || 'organic'

  useEffect(() => {
    if (
      !userId ||
      !growthBook ||
      !country ||
      !isReady ||
      (user && isExperimentsLoading)
    ) {
      return
    }

    const isMobile = navigator.maxTouchPoints > 0 && 'orientation' in window

    const deviceId = getDeviceIdFromCookies()

    const attributes: Record<string, any> = {
      custom_user_id: userId,
      country,
      utm_source: utmSource,
      device: isMobile ? 'mobile' : 'desktop',
      device_id: deviceId,
      platform: 'web',
    }

    if (user && isSubscriptionFetched && isProfileFetched) {
      attributes.email = user.email
      attributes.self_learning_premium_access = hasCoursesPremium
        ? SubscriptionStatus.PREMIUM
        : SubscriptionStatus.FREE
      attributes.user_created_date = formatISO(
        new Date(user.metadata.creationTime || '')
      )
      attributes.completed_first_lesson = isFirstLessonCompleted

      if (experimentsData) {
        const experimentsInvolved = experimentsData?.value?.split(',') || []
        attributes.ff_experiments_involved = experimentsInvolved
      }
    }

    growthBook.updateAttributes(attributes)
    ;(window as any).userAttributes = growthBook.getAttributes()
  }, [
    userId,
    growthBook,
    country,
    utmSource,
    isReady,
    user,
    isSubscriptionFetched,
    isProfileFetched,
    hasCoursesPremium,
    isFirstLessonCompleted,
    isExperimentsLoading,
    experimentsData,
  ])
}

export default useAddGrowthBookAttributes
