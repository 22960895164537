import { LIVE_MODE } from './common'

export const COMPLIANCE_CONFIG = 'compliance_config_promova'
export const USE_MARKETING_CONSENT = 'use_marketing_consent'
export const PREMIUM_LESSONS_CONFIG = 'premium_lessons_config'
export const USE_FREE_UKRAINE = 'use_free_ukraine'
export const USE_SAND_BOX = 'use_sand_box'
export const SHOW_FREE_FOR_UKRAINE_BUTTON = 'show_free_for_ukraine_button'
export const USE_7DAY_CHALLENGE = 'use_7day_challenge'
export const USE_ALT_CANCELLATION_FLOW = 'use_alt_cancellation_flow'
export const USE_PRACTICE_WITH_AI_STEP = 'use_practice_with_ai_step'

type DefaultJsonValueType = {
  value: boolean | string | Record<string, any>
  split_analytics: {
    send_event: boolean
    send_user_prop: boolean
  }
}

const DEFAULT_JSON_VALUE: DefaultJsonValueType = {
  value: false,
  split_analytics: {
    send_event: false,
    send_user_prop: false,
  },
}

export const DEFAULT_REMOTE_CONFIG = {
  [COMPLIANCE_CONFIG]: {
    [USE_MARKETING_CONSENT]: true,
  },
  [PREMIUM_LESSONS_CONFIG]: '',
  [USE_FREE_UKRAINE]: false,
  [USE_SAND_BOX]: !LIVE_MODE,
  [SHOW_FREE_FOR_UKRAINE_BUTTON]: false,
  [USE_7DAY_CHALLENGE]: DEFAULT_JSON_VALUE,
  [USE_ALT_CANCELLATION_FLOW]: DEFAULT_JSON_VALUE,
  [USE_PRACTICE_WITH_AI_STEP]: DEFAULT_JSON_VALUE,
}
